import { useState, useEffect, useRef} from 'react';
import './classes/ReferralPage.css';
import { configData } from "../config";
import Loading from './Loading';


const CertificatePage = (uid) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
       
        setTimeout(() => {
          setIsLoading(false);
        }, 800);
        
     },[]);
     
     return ( 
        <>
  
     <div className="certificate-page">
{<Loading />}
    </div>
     
     </>
     );
};

export default CertificatePage;