import React, { useState, useEffect} from 'react';
import { configData } from "../config";
import { Link, useNavigate} from 'react-router-dom';
import MinicartLength from './MinicartLength';
import Loading from './Loading';
import ReactDOM from "react-dom/client";

function SezzlePaymentNotify() {
    
    const app_url = configData.APP_URL;
    const navigate = useNavigate();
    const order_id = localStorage.getItem('order_id');

    useEffect(()=>{
        SezzleSuccessNotify(order_id);
    },[])

    async function SezzleSuccessNotify(order_id) {
        const postData = {
          order_id: order_id
          }
       const response = await fetch(app_url+'smwwcommercepayment/notifysezzlepayment', {
              method: 'POST',
              body: JSON.stringify(postData),
              headers: {
                'Content-Type': 'application/json',
              },
              });
              const data = await response.json();
              if(data.data.result === "Success") {
                const order_id = localStorage.getItem('drupal_orderid');
                localStorage.removeItem("order_id");
                localStorage.removeItem("drupal_orderid");
                 const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
                 root.render(<MinicartLength length={0} />);
                    navigate("/smww/checkout/thankyou/"+order_id);
              } else {
               
              }
      }
      return (
        <><Loading /></>
      );
}
export default SezzlePaymentNotify;