import React, { useState, useRef, useEffect,useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { configData } from "../config";
import './classes/slider.scss';
import SizeChartModal from './SizeChartModal';
import PhotosCarousel from "./PhotosCarousel";
import "glider-js/glider.min.css";
import './classes/ProductDetail.css';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";

const ApparelProductDetail = () => {
  const {slug} = useParams();
  const [showModal, setShowModal] = useState(false);
  const [items, setItemList] = useState([]);
  const [photosToShow, setPhotos] = useState([]); 
  const [size, setSize] = useState([]);
  const [selectedsize, setSelectedSize] = useState([]);
  const [selectedsizelabel, setSelectedSizeLabel] = useState([]);
  const [price, setPrice] = useState();
  const [sizechart, setSizeChart] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [message, setSuccessMessage] = useState([]);

  const app_url = configData.APP_URL;

  const [quantity, setQuantity] = useState(1);


  const handleIncrease = () => {
      setQuantity(prevQuantity => (prevQuantity < 500 ? prevQuantity + 1 : prevQuantity));
  };


  const handleDecrease = () => {
      setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
  };

  const thumbnailsCarouselConfig = {
    itemWidth: 150,
    slidesToShow: "auto",
    slidesToScroll: "auto"
  };
  
  useEffect(() => {
   
    retrieveProductDetail();

 }, [])

  async function retrieveProductDetail() {
    const apparellistresponse = await fetch(
    app_url+'smwwcommerceapparel/getapparelproductdetail?slug='+slug,
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const apparellistdata = await apparellistresponse.json();
  const apparellist = apparellistdata.data.result;
  setItemList(apparellist);
  setPhotos(apparellist.images);   
  setPrice(apparellist.price);
  setSize(apparellist.size); 
  setSizeChart(apparellist.size_chart);
  console.log(apparellist)
  }
  const handlePriceChange  = async event => {
    event.preventDefault(); 
    if(event.target.value) {
      setSelectedSize(event.target.value);
      var index = event.nativeEvent.target.selectedIndex;
      var text = 'Size: '+event.nativeEvent.target[index].text;
      setSelectedSizeLabel(text);
    const postData = {
      size_id: event.target.value,
      slug: slug
      }
      const response = await fetch(app_url+'smwwcommerceapparel/getsizeprice', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if(data.data.price > 0) {
        
        setPrice(data.data.price);
        
      }
    }
  }
  const handleModalClose = () => {
    setShowModal(false);
  };
  const [currentPhotoIdx, setCurrentPhotoIdx] = React.useState(0);
  const onClickPhotoHandler = React.useCallback(
    (idx) => () => setCurrentPhotoIdx(idx),
    []
  );
  const onSlideChangeHandler = React.useCallback(
    (photoIdx) => {
      setCurrentPhotoIdx(photoIdx);
    },
    [setCurrentPhotoIdx]
  );
  const photos = useMemo(
    () =>
      photosToShow.map((photoUrl, idx) => {
        const key = `${photoUrl}_${idx}`;
        let className = "slide__content";
        if (currentPhotoIdx === idx) {
          className += " --current-selected";
        }
        return (
          <div
            key={key}
            className={className}
            onClick={onClickPhotoHandler(idx)}
          >
            <img src={photoUrl} />
          </div>
        );
      }),
    [onClickPhotoHandler, currentPhotoIdx, photosToShow]
  );
  const handleAddtoCart = async event => { 
    event.preventDefault();
    const postData = {
      quantity: quantity,
      price:price,
      product_sku:items.sku,
      apparels_product_size_id: selectedsize,
      apparels_product_size: selectedsizelabel,
      order_id: localStorage.getItem('order_id')
      }
      const response = await fetch(app_url+'smwwcommerceapparel/addtocart', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        const yii_session = data.data.session;
        if(data.data.result === "order_created") {
          if(yii_session.order_id) localStorage.setItem('order_id', yii_session.order_id);
          else localStorage.setItem('order_id', data.data.order_id);
          setSuccessMessage(data.data.messages.success);
          setShowSuccessMessage(true);
          const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
          root.render(<MinicartLength length={data.data.products.length} />);
      }
   } 
   

  return (
    <div className="product-detail-container">
     {showSuccessMessage && (
             <div className="success-message">
               <div className="success-message-header"><strong>Added Successfully</strong><span className="close" onClick={() => setShowSuccessMessage(false)}>×</span></div>
               <div className="success-message-content">
               <div className="message-content">
                 <div className="message-icon">
                   <i className="fa fa-check-circle"></i>
                 </div>
                 <div className="message-text">
                 {message.map((message, index) => (
                      <p>{message}</p>
                 ))}
                    
                 </div>
               </div>
               <div className="success-buttons">
               <Link to="/smww/cart">
                 <button className="view-cart-button">View Cart</button>
               </Link> 
               <Link to="/smww/checkout">
                 <button className="checkout-button">Checkout</button>
               </Link>   
                 <button className="close-button" onClick={() => setShowSuccessMessage(false)}>Close</button>
               </div>
               </div>
             </div>
           )}
     
      <nav className="breadcrumb">
        <Link to="/">Home</Link> &gt; <Link to="/store">Shop</Link> &gt; {items.name}
      </nav>
      
      <div className="product-detail-content">
        
        <div className="apparel-product-details">
          
          <div className="App">
      <div className="photos-gallery">
        <PhotosCarousel
          className="photo-wrapper"
          onSlideChange={onSlideChangeHandler}
          currentSlideIdx={currentPhotoIdx}
        >
          {photos}
        </PhotosCarousel>
        <PhotosCarousel
          className="thumbnails-wrapper"
          config={thumbnailsCarouselConfig}
          currentSlideIdx={currentPhotoIdx}
        >
          {photos}
        </PhotosCarousel>
      </div></div>
      <div className='apparel-right-content'>
      <h1>{items.name}</h1>
      
          <p className="apparel-price">${price}</p>
       
          {size && (
          <div className='apparel-size-container'>
            <select className='size-select' onChange={handlePriceChange}>
              <option value="">Select Size</option>
              {size.map((size, index) => (
                <><option key= {size.name} value={size.id}>{size.name}</option></>
              ))}
            </select>
            <a className='apparel-size-modal' onClick={() => setShowModal(true)}>View Size Chart</a>
          </div>
          )}
           <div className="quantity-container">
            <div className="quantity-selector">
                <button className='quantity-container-button1' onClick={handleDecrease}>-</button>
                <input
					className="quantity-selector-input"							   
                    type="number"
                    min="1"
                    max="10"
                    value={quantity}
                    readOnly
					
                />
                <button className='quantity-container-button2' onClick={handleIncrease}>+</button>
            </div>
            <button  className="apparel-add-to-cart" onClick={handleAddtoCart} >Add to Cart</button>
        </div>

          <hr className='apparel-hr' />          
          <p className="description">
          <div dangerouslySetInnerHTML={{__html: items.disclaimer_text}} />
           
          </p>
          </div>
        </div>
        <div className="referral-wallet">
          <Link to="/login">
            <img src={`${process.env.PUBLIC_URL}/images/apparel/referral_ad.png`} alt="lynn" className="paragraph-image" />
          </Link>  
        </div>
      </div>
      <SizeChartModal show={showModal} title={items.name} data={sizechart} onClose={handleModalClose} />
    </div>
  );
};

export default ApparelProductDetail;
