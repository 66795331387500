import React, { useState, useEffect, useRef } from 'react';
import { configData } from "../config";
import { Link, useNavigate } from 'react-router-dom';
import Loading from './Loading';

function PaypalPayment() {

    const app_url = configData.APP_URL;
    const  paypal_return_domain = configData.DOMAIN_PUBLIC_URL;
    const form=useRef();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        city: '',
        country: '',
        state: '',
        zip: '',
        address1: '',
        address2: '',
        upload: 1,
        business: 'sb-0cdsv34432648@business.example.com',
        cmd: '_cart',
        notify_url: paypal_return_domain+'/basic/web/api/smwwcommercepayment/notifyinstantpaypalpayment?order_id='+localStorage.getItem('drupal_orderid'),
        bn:'CommerceGuys_Cart_PPS',
        charset:'utf-8',
        no_note:1,
        no_shipping:1,
        cancel_return:paypal_return_domain+'/smww/cart',
        return: paypal_return_domain+'/smww/checkout/thankyou/'+localStorage.getItem('drupal_orderid'),
        rm:2,
        paymentaction:'sale',
        currency_code:'USD',
        lc:'US',
        invoice:'',
        custom:'Test',
        amount_1:'',
        item_name_1:'Test',
        on0_1:'Product count',
        os0_1:''
      });
      const [loading, setLoading] = useState(false);
     

      useEffect(()=>{
        retrieveBillingInfo();
        retrieveCartProducts();
        setTimeout(() => {
        form.current.submit();
      }, 800);
      },[])
      
      async function retrieveBillingInfo() {
        const order_id = localStorage.getItem('order_id');
        if( order_id) {
          const response = await fetch(
          app_url+'smwwcommercepayment/getcustomerbillingdetails?order_id='+order_id,
          {
            method: "GET",
    
            headers: {
              'Content-Type': 'application/json',
            },
          }
          )
          const responsedata = await response.json();
          const data = responsedata.data.result;
          
          if(data) {
           
            setFormData(prevState => ({
            ...prevState,
            email: data.email,
            country: data.country,
            state: data.state,
            first_name: data.firstname,
            last_name: data.lastname,
            address1: data.address,
            address2: data.address1,
            zip: data.zipcode,
            city: data.city,
           
            }));
          }
        }  
      }
      async function retrieveCartProducts() {
        const order_id = localStorage.getItem('order_id');
        if(order_id) {
          const response = await fetch(
            app_url+'smwwcommercecheckout/getorderdetails?order_id='+order_id,
            {
            method: "GET",
    
            headers: {
              'Content-Type': 'application/json',
            },
          }
         )
          const responsedata = await response.json();
          const date = new Date();
          const item_name = 'Order '+order_id+' at SMWW';
          setFormData(prevState => ({
            ...prevState,
            invoice: order_id+'-'+date,
            os0_1: responsedata.data.products.length,
            item_name_1:item_name,
            amount_1: responsedata.data.order_total,
          }));
          setLoading(true);
          
        }
      }
      return ( 
        
        <form  action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post" ref={form} name="commerce-paypal-wps-redirect-form" 
        id="commerce-paypal-wps-redirect-form" accept-charset="UTF-8">
        <input type="hidden" name="first_name" value={formData.first_name}></input>
        <input type="hidden" name="last_name" value={formData.last_name}></input>
        <input type="hidden" name="address1" value={formData.address1}></input>
        <input type="hidden" name="address2" value={formData.address2}></input>
        <input type="hidden" name="city" value={formData.city}></input>
        <input type="hidden" name="state" value={formData.state}></input>
        <input type="hidden" name="zip" value={formData.zip}></input>
        <input type="hidden" name="country" value={formData.country}></input>
        <input type="hidden" name="email" value={formData.email}></input>
        <input type="hidden" name="cmd" value={formData.cmd}></input>
        <input type="hidden" name="upload" value={formData.upload}></input>
        <input type="hidden" name="business" value={formData.business}></input>
        <input type="hidden" name="notify_url" value={formData.notify_url}></input>
        <input type="hidden" name="bn" value={formData.bn}></input>
        <input type="hidden" name="charset" value={formData.charset}></input>
        <input type="hidden" name="no_note" value={formData.no_note}></input>
        <input type="hidden" name="no_shipping" value={formData.no_shipping}></input>
        <input type="hidden" name="cancel_return" value={formData.cancel_return}></input>
        <input type="hidden" name="return" value={formData.return}></input>
        <input type="hidden" name="rm" value={formData.rm}></input>
        <input type="hidden" name="paymentaction" value={formData.paymentaction}></input>
        <input type="hidden" name="currency_code" value={formData.currency_code}></input>
        <input type="hidden" name="lc" value={formData.lc}></input>
        <input type="hidden" name="invoice" value={formData.invoice}></input>
        <input type="hidden" name="custom" value={formData.custom}></input>
        <input type="hidden" name="amount_1" value={formData.amount_1}></input>
        <input type="hidden" name="item_name_1" value={formData.item_name_1}></input>
        <input type="hidden" name="on0_1" value={formData.on0_1}></input>
        <input type="hidden" name="os0_1" value={formData.os0_1}></input>
        </form>
      )
}
export default PaypalPayment;