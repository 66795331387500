import React, { useState, useEffect} from 'react';
import { configData } from "../config";
import { useNavigate, useSearchParams } from 'react-router-dom';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";

function Logout() {
   
    const app_url = configData.APP_URL;
    const agency_domain_url = configData.AGENCY_DOMAIN_URL;
    const scout_domain_url = configData.SCOUT_DOMAIN_URL;
    const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
    var loggedin_domain = drupal_domain_url;
    if(localStorage.getItem('loggedin_domain')) {
      loggedin_domain = localStorage.getItem('loggedin_domain');
    } 
    const navigate = useNavigate();
    useEffect(()=>{
     
      localStorage.removeItem('uid');
              localStorage.removeItem('username');
              localStorage.removeItem('email');
              localStorage.removeItem('order_id');
              localStorage.removeItem('drupal_uid');
              localStorage.removeItem('session_id');
              localStorage.removeItem('order_id');
              localStorage.removeItem('shippingfields');
              localStorage.removeItem('sel_country');
              const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
              root.render(<MinicartLength length={0} />,document.getElementById("totalItems"));
              navigate("/user");
    },[])
   
   
}
export default Logout;