import React, { useState, useEffect,useRef, createRef} from 'react';
import './classes/Application.css';
import { configData } from "../config";
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-dropdown-select'
import 'react-phone-number-input/style.css'
import Loading from './Loading';
import PhoneInput, {
	formatPhoneNumber,
	formatPhoneNumberIntl,
	isPossiblePhoneNumber,
	isValidPhoneNumber
} from 'react-phone-number-input'


function ApplicationForm() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    primary_phone: '',
    city: '',
    country: 'US',
    state: '',
    province: '',
    field_favteam: '',
    field_secondary_phone: '',
    street_address: '',
    field_street_2: '',
    postal_code: '',
    birthdayMonth: '',
    birthdayDay: '',
    birthdayYear: '',
    gender: '',
    appliedCourses:[],
    cuc_degrees:[],
    story: '',
    field_current_job: '',
    educationLevel: '',
	field_university: '',
    field_major: '',
    field_last_attended: '',					
    field_military: '',
    field_military_id: '',
    field_highest_grade: '',
    field_sports_recom: '',
    field_linkedin_url: '',
    pageURL: window.location.href, 
    showAdditionalFieldsStep2: false,
    showAdditionalFieldsStep3: false,
    showWelcomeTextStep2: true,
    showWelcomeTextStep3: true,
    showStateField: true,
    showProvinceField: false,
	  showEducationFields: false,
    newUser: 0,				   
    continueone: true ,
    continuetwo:false,
  });

  const [labels, setLabels] = useState({});
  const [errors, setErrors] = useState({});
  const [errortexts, setErrorTexts] = useState({});
  const [errorvalidationrules, setErrorValidationRule] = useState({});
  const [statelist, setStateList] = useState({});
  const [countrieslist, setCountriesList] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [buttontext, setButtonText] = useState("Submit");
  const app_url = configData.APP_URL;
  const recaptchaRef = useRef(null);
  const sitekey = configData.GOOGLE_RECAPTCHA_SITE_KEY;
  const navigate = useNavigate();
  const uid = localStorage.getItem('uid');
  const [selectedcountry, setSelectedCountry] = useState([{ label: 'United States', value: 'US' }]);
	const [searchValue, setSearchValue] = useState('');															
  const dropdownRef = createRef();

  const handleOpen = () => {
    setSelectedCountry([]);
    setFormData(prevState => ({
      ...prevState,
      country:''
    }));

  };
  const generateYearOptions = () => {
    const arr = [];
  
    const startYear = 1900;
    const endYear = new Date().getFullYear();
  
    for (let i = endYear; i >= startYear; i--) {
      arr.push(<option value={i}>{i}</option>);
    }
  
    return arr;
  };
  
  const generateMonthOptions = () => {
    const arr = [];
      var MonthArray =  [{id:'01',name:'Jan'},
        {id:'02',name:'Feb'},
        {id:'03',name:'Mar'},
        {id:'04',name:'Apr'},
        {id:'05',name:'May'},
        {id:'06',name:'Jun'},
        {id:'07',name:'Jul'},
        {id:'08',name:'Aug'},
        {id:'09',name:'Sep'},
        {id:'10',name:'Oct'},
        {id:'11',name:'Nov'},
        {id:'12',name:'Dec'}];
      MonthArray.sort(function(a, b){
        return a.id - b.id;
    });
     
    
    MonthArray.forEach(function(item){
      arr.push(<option value={item.id}>{item.name}</option>);
    });
  
    return arr;
  };
  const generateDayOptions = () => {
    const arr = [];
    const startDay = 1;
    const endDay = 31;
  
    for (let i = startDay; i <= endDay; i++) {
      if(i<10) i = '0'+i;
      arr.push(<option value={i}>{i}</option>);
    }
  
    return arr;
  };
  useEffect(() => {
    retrieveFieldsVaribles();
    retrieveFieldsErrorText();
    retrieveStateList();
    retrieveCountriesList();
    if(uid > 0) {
      navigate("/smww_pay");
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
    
 }, [])
 async function retrieveFieldsVaribles() {
    const fieldslabelresponse = await fetch(
      app_url+'formfields/getfieldlabels?form_name=application_form&type=label',
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const fieldslabeldata = await fieldslabelresponse.json();
    
    const labels = {};
    Object.keys(fieldslabeldata.data.result).forEach(function(key) {
      
      labels[key] = fieldslabeldata.data.result[key];
    
    });
    setLabels(labels);
    
  }
  async function retrieveFieldsErrorText() {
    const fieldserrorresponse = await fetch(
      app_url+'formfields/getfielderrortext?form_name=application_form&type=error',
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const fieldserrordata = await fieldserrorresponse.json();
    const errortexts = {};
    const errorvalidationrules = {};
   
    Object.keys(fieldserrordata.data.result).forEach(function(key) {
      
      errortexts[key] = fieldserrordata.data.result[key]['error_msg'];
      errorvalidationrules[key] = fieldserrordata.data.result[key]['validation_rule'];
    
    });
    setErrorTexts(errortexts);
    setErrorValidationRule(errorvalidationrules);
  }
  async function retrieveStateList() {
    const statelistresponse = await fetch(
      app_url+'smwwstates/getstatelist',
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const statelistdata = await statelistresponse.json();
    
    const statelist = {};
    Object.keys(statelistdata.data.result).forEach(function(key) {
      
      statelist[key] = statelistdata.data.result[key];
    
    });
    setStateList(statelist);
   
  }
  async function retrieveCountriesList() {
    const countrieslistresponse = await fetch(
      app_url+'smwwcountries/getcountrieslist',
      {
        method: "GET",

        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const countrieslistdata = await countrieslistresponse.json();

    setCountriesList(countrieslistdata.data.result);
   
    
    
  }
  const handleChange = event => {
    const { name, value} = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  
    if(event.target.name === 'country') {
      if(event.target.value === 'US' || event.target.value === 'CA') {
        setFormData(prevState => ({
          ...prevState,
          showProvinceField: false,
          showStateField: true,
        }));

      } else {
        setFormData(prevState => ({
          ...prevState,
          showProvinceField: true,
          showStateField: false,
        }));
       
      }
    
    }
    if(event.target.name === 'appliedCourses') {
      const isChecked = event.target.checked;
      if(isChecked){
      setFormData(prevState => ({
        ...prevState,
        appliedCourses: [...formData.appliedCourses, event.target.value],
        
      }));
    } else {
      let index = formData.appliedCourses.indexOf(event.target.value);
      formData.appliedCourses.splice(index, 1);
      setFormData(prevState => ({
        ...prevState,
        appliedCourses: formData.appliedCourses,
        
      }));
    }
    }
    if(event.target.name === 'cuc_degrees') {
      const isChecked = event.target.checked;
      if(isChecked){
      setFormData(prevState => ({
        ...prevState,
        cuc_degrees: [...formData.cuc_degrees, event.target.value],
        
      }));
    } else {
      let index = formData.cuc_degrees.indexOf(event.target.value);
      formData.cuc_degrees.splice(index, 1);
      setFormData(prevState => ({
        ...prevState,
        cuc_degrees: formData.cuc_degrees,
        
      }));
    }
    }
	if(event.target.name === 'field_highest_grade') {
      if(event.target.value === '') {
        setFormData(prevState => ({
        ...prevState,
        showEducationFields:false,
        }));
      } else {
        setFormData(prevState => ({
          ...prevState,
          showEducationFields:true,
          }));
      }
    }
  };
  
  const validateForm = (data) => {
    const errors = {};
    const recaptchaValue = recaptchaRef.current.getValue();
    const first_step_fields = ["first_name","last_name","email","primary_phone","city","country","state","field_favteam"];
    Object.keys(data).forEach(function(key) {
     
      if (!data[key] && errorvalidationrules[key] === "NULL" && first_step_fields.includes(key)) {
      
        if(errortexts[key]) errors[key] = errortexts[key];
        else errors[key] = labels[key] + ' is required';
     }
    
    });
    
    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }
    if(!isValidPhoneNumber(data.primary_phone)) {
      errors.primary_phone = 'Invalid Phone Number';
    }
   
    if (errorvalidationrules.state.includes(data.country) && data.country != '') {
      
      if (!data.state.trim()) {
        if(errortexts.state) errors.state = errortexts.state;
        else errors.state = 'State is required';
      }
    }
  
    if (!recaptchaValue) {
      errors.recaptcha = 'Please verify the reCAPTCHA!';
    }
   
    return errors;
  };
  const validateForm2 = (data) => {
    const errors = {};

    if (!data.street_address.trim()) {
      if(errortexts.street_address) errors.street_address = errortexts.street_address;
      else errors.street_address = 'Street Address is required';
    }
    if (!data.gender.trim()) {
      if(errortexts.gender) errors.gender = errortexts.gender;
      else errors.gender = 'Gender is required';
    }
    if (!data.birthdayDay.trim() || !data.birthdayMonth.trim() || !data.birthdayYear.trim()) {
      if(errortexts.field_birthday) errors.birthday = errortexts.field_birthday;
      else errors.birthday = 'Birthday is required';
    }
    if (!data.postal_code.trim()) {
      if(errortexts.postal_code) errors.postal_code = errortexts.postal_code;
      else errors.postal_code = 'Postal Code is required';
    }
    if (data.country == "US") {
      const postData = {
        country: formData.country,
        state: formData.state,
        zip: formData.postal_code 
        }
        const response = fetch(app_url+'application/validatezipcode', {
          method: 'POST',
          body: JSON.stringify(postData),
          headers: {
            'Content-Type': 'application/json',
          },
          });
          
         
    }
    
    return errors;
  };
  const validateForm3 = (data) => {
    const errors = {};
    console.log(data.appliedCourses);
    if(data.appliedCourses.length == 0) {
      errors.appliedCourses = 'Please select any one of the course';
    }
    if (!data.story.trim()) {
      errors.story = 'Tell Us story is required';
    }
    if (!data.field_highest_grade.trim()) {
      errors.field_highest_grade = 'Education is required';
    }
   if(data.field_linkedin_url.trim() !== '') {
    var linkedinurl = data.field_linkedin_url.toLowerCase();
    if (linkedinurl.includes("linkedin.com/") === false) {
      errors.field_linkedin_url = 'Please enter a valid LinkedIn URL';
      
    }
   }
    
    return errors;
  };
  
  const handleSubmitStep1 = async event => {
    event.preventDefault();
   
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    
    if (Object.keys(newErrors).length === 0) {
      setDisabled(true);
      const postData = formData; 
      const response = await fetch(app_url+'application/step1', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      if(data.data.result === "user_created") {
        setDisabled(false);
        
        if(data.data.user_status === "exist_user") {
           setFormData(prevState => ({
          ...prevState,
          showAdditionalFieldsStep2: true,
          continueone: false,
          continuetwo: true,
          showWelcomeTextStep2: false,
    
          }));
        
        } else {
          setFormData(prevState => ({
          ...prevState,
          showAdditionalFieldsStep2: true,
          continueone: false,
          continuetwo: true,
          newUser: 1,
          }));
        }
      }
    }
  };
  const handleSubmitStep2 = async event => {
    event.preventDefault();
    const second_step_fields = ["street_address","gender","field_birthday","postal_code"];
    const errors = {};
    Object.keys(formData).forEach(function(key) {
     
      if (!formData[key] && errorvalidationrules[key] === "NULL"  && second_step_fields.includes(key)) {
      
        if(errortexts[key]) errors[key] = errortexts[key];
        else errors[key] = labels[key] + ' is required';
     }
    
    });
    if (!formData.birthdayDay.trim() || !formData.birthdayMonth.trim() || !formData.birthdayYear.trim()) {
      if(errortexts.field_birthday) errors.field_birthday = errortexts.field_birthday;
      else errors.field_birthday = 'Birthday is required';
    }
    if (formData.country == "US") {
      const postData = {
        country: formData.country,
        state: formData.state,
        zip: formData.postal_code 
        }
        const response = await fetch(app_url+'application/validatezipcode', {
          method: 'POST',
          body: JSON.stringify(postData),
          headers: {
            'Content-Type': 'application/json',
          },
          });
          const data = await response.json();
          if(data.data.result === "Not Valid") {
            errors.postal_code = 'Invalid Zipcode';
          }
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setDisabled(true);
      const postData = formData; 
   
      const response = await fetch(app_url+'application/step2', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
      });
    const data = await response.json();
    if(data.data.result === "user_created") {
      setDisabled(false);
      if(data.data.user_status === "exist_user") {
         setFormData(prevState => ({
        ...prevState,
        showAdditionalFieldsStep3: true,
        continueone: false,
        continuetwo: false,
        showWelcomeTextStep3: false,
  
        }));
        if(formData.newUser == 1) {
          setFormData(prevState => ({
            ...prevState,
            showWelcomeTextStep3: true,
      
            }));
        }
      } else {
        setFormData(prevState => ({
        ...prevState,
        showAdditionalFieldsStep3: true,
        continueone: false,
        continuetwo: true,
        showWelcomeTextStep3: true,
        }));
      }
    }
    
  }
  
  };
  const handleSubmitStep3 = async event => {
    event.preventDefault();
    const newErrors = validateForm3(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setDisabled(true);
      setButtonText("Saving...");
    const postData = formData; 
   
    const response = await fetch(app_url+'application/step3', {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    if(data.data.result === "user_created") {
      setDisabled(false);
      const shippingfields = {
        'email':formData.email,
        'first_name':formData.first_name,
        'last_name': formData.last_name,
        'primary_phone': formData.primary_phone,
        'street_address': formData.street_address,
        'city' : formData.city,
        'field_street_2': formData.field_street_2,
        'postal_code': formData.postal_code,
        'country':formData.country
    };
   
    const jsonObject = JSON.stringify(shippingfields);
    localStorage.setItem('shippingfields', jsonObject);
      localStorage.setItem('sel_country', formData.country);
      navigate("/smww_pay",{state:'Thank you for your interest! Someone from SMWW will be contacting you soon. In the meantime, you can view our courses below.'});
    }
    
  }
  
  };

  return (
    <>
    {isLoading ? (
      <Loading />
    ) : (
    <div className='application-form-container'>
      
     <Helmet><title>Apply Today</title></Helmet> 
    <form  className="form-container">
    <h1>Start Your Application</h1>
    <p className='starting-paragraph'>The application is completely free and takes less than 2 minutes to complete.</p>
    
    <p>After filling it out, one of our Sports Career Advisors will contact you within one business day to assess your career goals 
      and help place you in the right program.</p>

      <div className='smww-appn-hr' />
    

      <p className='legend'><span className="fieldset-legend">Contact Information</span></p>

      <div className="form-row">
        <div className="form-group">
          <label htmlFor="first_name">{labels.first_name ? ( labels.first_name ) : ( "First Name" ) } <span className="form-required" title="This field is required.">*</span></label>
          <input className='smww-appn' type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} />
          {errors.first_name &&
            <span className="error-message">
               {errors.first_name}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="last_name">{labels.last_name ? ( labels.last_name ) : ( "Last Name" ) } <span className="form-required" title="This field is required.">*</span></label>
          <input className='smww-appn' type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} />
          {errors.last_name &&
            <span className="error-message">
              {errors.last_name}
            </span>
          }
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label htmlFor="email">{labels.email ? ( labels.email ) : ( "Email" ) } <span className="form-required" title="This field is required.">*</span></label>
          <input className='smww-appn' type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
          {errors.email &&
            <span className="error-message">
              {errors.email}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="primary_phone">{labels.primary_phone ? ( labels.primary_phone ) : ( "Primary Phone Number" ) } <span className="form-required" title="This field is required.">*</span></label>
         
         
                 <PhoneInput
                 international
                 countryCallingCodeEditable={false}
                  placeholder="Enter phone number"
                  className='smww-appn'
                value={formData.primary_phone}
                defaultCountry="US" 
                onChange={(phone) =>
                  handleChange({ target: { value: phone, name: 'primary_phone' } })
              }
            />
          {errors.primary_phone &&
            <span className="error-message">
              {errors.primary_phone}
            </span>
          }
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label htmlFor="city">{labels.city ? ( labels.city ) : ( "City/Town" ) } <span className="form-required" title="This field is required.">*</span></label>
          <input className='smww-appn' type="text" id="city" name="city" value={formData.city} onChange={handleChange} />
          {errors.city &&
            <span className="error-message">
              {errors.city}
            </span>
          }
        </div>
        <div className="form-group">
          <label htmlFor="country">{labels.country ? ( labels.country ) : ( "Country" ) } <span className="form-required" title="This field is required.">*</span></label>
         
				  


          <Select 
          
          id="country" name="country" className='select-country smww-appn'
          searchable={true}
          required={true}  
          clearable={true} 
          clearOnBlur={true} 
          clearOnSelect={true}
          backspaceDelete={true} 
           
          
          style={{backgroundColor:'#ffffff', width:'87.8%', borderRadius: 5}}
															
          options={countrieslist} 
          onChange={(val) => {
            if(val.length == 0) {
              console.log(val);
            }
            else{
              setSelectedCountry(val);
            handleChange(
              { target: { value: val[0]['value'], name: 'country' } }
            )
            
            }}
          }
          onDropdownOpen={handleOpen}
          values={selectedcountry} 
          
          />
          {errors.country &&
            <span className="error-message">
              {errors.country}
            </span>
          }
        </div>
      </div>

      <div className="form-row">
        {formData.showStateField &&
          <div className="form-group state">
             
            <label htmlFor="province" className='province'>{labels.state ? ( labels.state ) : ( "State/Province/Region" ) }</label>
            <select className='smww-appn' id="state" name="state" value={formData.state} onChange={handleChange}>
            <option value="">Select State</option>
            {Object.keys(statelist).map(state => {
              return <option key={statelist[state].name} value={statelist[state].name}>{statelist[state].name}</option>
            })}
            </select>
            {errors.state &&
            <span className="error-message">
              {errors.state}
            </span>
          }
          </div>
        }
        {formData.showProvinceField &&
          <div className="form-group province">
            <label htmlFor="province">{labels.province ? ( labels.province ) : ( "State/Province/Region" ) }</label>
            <input className='smww-appn' type="text" id="province" name="province" value={formData.province} onChange={handleChange} />
          </div>
        }
        <div className="form-group">
          <label htmlFor="field_favteam">{labels.field_favteam ? ( labels.field_favteam ) : ( "If you could work for your favorite sports organization, who would it be?" ) } <span className="form-required" title="This field is required.">*</span></label>
          <input className='smww-appn' type="text" id="field_favteam" name="field_favteam" value={formData.field_favteam} onChange={handleChange} />
          {errors.field_favteam &&
            <span className="error-message">
              {errors.field_favteam}
            </span>
          }
          <input className='smww-appn' type="hidden" name="pageURL" id="pageURL" value={formData.pageURL} onChange={handleChange}/>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <div className="recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={sitekey}
              size="visible"
              onChange={handleChange}
              required
            />
          </div>
          {errors.recaptcha &&
            <span className="error-message">
              {errors.recaptcha}
            </span>
          }
        </div>
      </div>
      {formData.continueone && (
        <>
        <button className='continue-one smww-appn-button' name="continue-one" type="submit" onClick={handleSubmitStep1} disabled={disabled}>Continue</button>
        {disabled && (
          <div>
            <img src={`${process.env.PUBLIC_URL}/images/ajax-loader.gif`} alt="loader" className='loader' />
          </div>
        )}
        </>
      )}
      {formData.showAdditionalFieldsStep2 && (
        
        <>
          {formData.showWelcomeTextStep2 && (
             <p className="additional-text">
                 Thank you for getting started. To see if you qualify for acceptance, please fill out the rest of the form.
              </p>
          )}
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="field_secondary_phone">{labels.field_secondary_phone ? ( labels.field_secondary_phone ) : ( "Secondary Phone Number" ) }</label>
              <input className='smww-appn' type="tel" id="field_secondary_phone" name="field_secondary_phone" value={formData.field_secondary_phone} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="street_address">{labels.street_address ? ( labels.street_address ) : ( "Street Address" ) } <span className="form-required" title="This field is required.">*</span></label>
              <input className='smww-appn' type="text" id="street_address" name="street_address" value={formData.street_address} onChange={handleChange} />
              {errors.street_address &&
                <span className="error-message">
                {errors.street_address}
                </span>
              }
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="field_street_2">{labels.field_street_2 ? ( labels.field_street_2 ) : ( "Address (cont.)" ) }</label>
              <input className='smww-appn' type="text" id="field_street_2" name="field_street_2" value={formData.field_street_2} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="postal_code">{labels.postal_code ? ( labels.postal_code ) : ( "Zip/Postal Code" ) } <span className="form-required" title="This field is required.">*</span></label>
              <input className='smww-appn' type="text" id="postal_code" name="postal_code" value={formData.postal_code} onChange={handleChange} />
              {errors.postal_code &&
                <span className="error-message">
                {errors.postal_code}
                </span>
              }
            </div>
          </div>

          <div className="form-row">
            <div className="form-group birthday-group">
              <label htmlFor="birthday">{labels.field_birthday ? ( labels.field_birthday ) : ( "What is your Birthday?" ) } <span className="form-required" title="This field is required.">*</span></label>
              <div className="birthday-inputs">
                <select className='smww-appn' id="birthdayMonth" name="birthdayMonth" value={formData.birthdayMonth} onChange={handleChange}>
                  <option value="">Month</option>
                  {generateMonthOptions()}
                </select>
                <select className='smww-appn appn-bdy' id="birthdayDay" name="birthdayDay" value={formData.birthdayDay} onChange={handleChange}>
                  <option value="">Day</option>
                  {generateDayOptions()}
                </select>
                <select className='smww-appn appn-bdy' id="birthdayYear" name="birthdayYear" value={formData.birthdayYear} onChange={handleChange}>
                  <option value="">Year</option>
                  {generateYearOptions()}
                  
                </select>
              </div>
              {errors.field_birthday &&
                <span className="error-message">
                  {errors.field_birthday}
                </span>
              }
            </div>
            <div className="form-group">
              <label>{labels.gender ? ( labels.gender ) : ( "Gender" ) } <span className="form-required" title="This field is required.">*</span></label>
              <div required className="gender-radio">
                <label>
                  <input className='smww-appn' type="radio" name="gender" value="Male" onChange={handleChange} />
                  Male
                </label>
                <label>
                  <input className='smww-appn' type="radio" name="gender" value="Female" onChange={handleChange} />
                  Female
                </label>
                <label>
                  <input className='smww-appn' type="radio" name="gender" value="Non-binary" onChange={handleChange} />
                  Non-binary
                </label>
                <label>
                  <input className='smww-appn' type="radio" name="gender" value="Prefer not to answer" onChange={handleChange} />
                  Prefer not to answer
                </label>
              </div>
              {errors.gender &&
                <span className="error-message">
                  {errors.gender}
                </span>
              }
            </div>

          </div>
         
          
        </>
      )}
      {formData.continuetwo && (
        <>
        <button className='continue-two smww-appn-button' name="continue-two" type="submit" onClick={handleSubmitStep2}>Continue</button>
        {disabled && (
          <div>
            <img src={`${process.env.PUBLIC_URL}/images/ajax-loader.gif`} alt="loader" className='loader' />
          </div>
        )}
        </>
      )}
      {formData.showAdditionalFieldsStep3 && (
        
        <>
        {formData.showWelcomeTextStep3 && (
          <p className="additional-text">
             You’re almost done! The more you tell us, the faster we can advance your career.
          </p>
        )}



{/* --------------------------------------Select your top 5 areas of interest------------------------------------- */}


<div className="form-row area-interest-row">
  <div className="form-group ai-group">
    
    <label>Select your top 5 areas of interest <span className="form-required" title="This field is required.">*</span></label>
    <div required className="edit-courses-group">
    <h3>Courses</h3>
    <div required className="checkbox-container">
    
      <div className='ai-column1'>
        <div className="category">
          <img src={`${process.env.PUBLIC_URL}/images/appn/Agent.png`} alt="agent" className='dollar-img' /> <h4>Agent</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-505" name="appliedCourses" value="AM400" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-505">Athlete Management (Sports Agent)</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-191055" name="appliedCourses" value="AMB" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-191055">Athlete Marketing & Branding</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-167018" name="appliedCourses" value="BAM" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-167018">Baseball Agent</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-187229" name="appliedCourses" value="BBAM" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-187229">Basketball Agent</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-318567" name="appliedCourses" value="SCPC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-318567">FIFA Agent Prep</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-815" name="appliedCourses" value="FACPC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-815">Football Agent Certification Prep</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-261641" name="appliedCourses" value="HAM" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-261641">Hockey Agent</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-217237" name="appliedCourses" value="NIL" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-217237">Name, Image, and Likeness</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-710" name="appliedCourses" value="CAPOLOGY" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-710">Salary Cap & Contract Negotiation</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-803" name="appliedCourses" value="SAM" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-803">Soccer Agent</label>
          </div>
        </div>

        <div className="category">
          <img src={`${process.env.PUBLIC_URL}/images/appn/Baseball.png`} alt="baseball" /> <h4>Baseball</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-796" name="appliedCourses" value="BAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-796">Baseball Analytics</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-506" name="appliedCourses" value="BGM100" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-506">Baseball GM & Scouting</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-160171" name="appliedCourses" value="BPD" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-160171">Baseball Player Development</label>
          </div>
        </div>

        <div className="category">
          <img src={`${process.env.PUBLIC_URL}/images/appn/Basketball.png`} alt="basketball" /> <h4>Basketball</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-1075" name="appliedCourses" value="BBAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-1075">Basketball Analytics</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-507" name="appliedCourses" value="BGMSC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-507">Basketball GM & Scouting</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-217236" name="appliedCourses" value="BBPD" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-217236">Basketball Player Development</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-510" name="appliedCourses" value="DVS" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-510">Basketball Video Performance Analysis With Synergy</label>
          </div>
        </div>

       
      </div>

      <div className='ai-column2'>



      <div className="category">
          
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-509" name="appliedCourses" value="CMA100" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-509"><img src={`${process.env.PUBLIC_URL}/images/appn/Cricket.png`} alt="cricket" /> <h4>Cricket Business Management</h4></label>
          </div>
        </div>

        <div className="category">
          
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-91755" name="appliedCourses" value="ESPORTS" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-91755"><img src={`${process.env.PUBLIC_URL}/images/appn/Esports.png`} alt="esports" /> <h4>Esports Business</h4></label>
          </div>
        </div>

        <div className="category">
          <img src={`${process.env.PUBLIC_URL}/images/appn/Football.png`} alt="football" /> <h4>Football</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-3428" name="appliedCourses" value="FAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-3428">Football Analytics</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-511" name="appliedCourses" value="FGMSC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-511">Football GM & Scouting</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-176314" name="appliedCourses" value="FPD" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-176314">Football Player Development</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-2047" name="appliedCourses" value="DVE" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-2047">Football Video Performance Analysis With Catapult</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-392692" name="appliedCourses" value="PFF" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-392692">Proficiency in PFF Premium</label>
          </div>
        </div>      

        <div className="category">
          <img src={`${process.env.PUBLIC_URL}/images/appn/Hockey.png`} alt="hockey" /> <h4>Hockey</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-1076" name="appliedCourses" value="HAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-1076">Hockey Analytics</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-513" name="appliedCourses" value="HGM100" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-513">Hockey GM & Scouting</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-193908" name="appliedCourses" value="HPD" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-193908">History Player Development</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-2047" name="appliedCourses" value="DVE" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-2047">Hockey Video Performance Analysis With Catapult</label>
          </div>
        </div>  


        <div className="category">
          
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-514" name="appliedCourses" value="ARMC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-514"><img src={`${process.env.PUBLIC_URL}/images/appn/Motorsports.png`} alt="motorsports" /> <h4>Motorsports Management</h4></label>
          </div>
        </div>

        <div className="category">
          
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-516" name="appliedCourses" value="RM100" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-516"><img src={`${process.env.PUBLIC_URL}/images/appn/Rugby.png`} alt="rugby" /> <h4>Rugby Business Management</h4></label>
          </div>
        </div>        

      </div>

      <div className='ai-column3'>




        <div className="category">
          <img src={`${process.env.PUBLIC_URL}/images/appn/Soccer.png`} alt="soccer" /> <h4>Soccer / Football / Fútbol</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-318567" name="appliedCourses" value="SCPC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-318567">FIFA Agent Prep</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-822" name="appliedCourses" value="SAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-822">Soccer Analytics</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-517" name="appliedCourses" value="SGM100" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-517">Soccer Management & Scouting</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-217239" name="appliedCourses" value="STI" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-217239">Soccer Player Development</label>
          </div>
        </div>

        <div className="category">
          <img src={`${process.env.PUBLIC_URL}/images/appn/All_Sports1.png`} alt="all sports" /> <h4>All Sports</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-508" name="appliedCourses" value="COACH" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-508">Coaching At The Next Level</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-105373" name="appliedCourses" value="DSA" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-105373">Sports Analytics and AI</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-518" name="appliedCourses" value="HSAD" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-518">Sports Administration</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-519" name="appliedCourses" value="SB100" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-519">Sports Broadcasting</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-111716" name="appliedCourses" value="BETTING" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-111716">Sports Cryptocurrency</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-521" name="appliedCourses" value="SPORTSPR" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-521">Sports Media & Content Strategy</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-520" name="appliedCourses" value="SRM400" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-520">Sports Sales & Marketing</label>
          </div>

        </div>

        <div className="category">
          <img src={`${process.env.PUBLIC_URL}/images/appn/star_1.png`} alt="certificates" /> <h4>Advanced Certificates</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-266016" name="appliedCourses" value="ARAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-266016">Sports Agent Certificate</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-266015" name="appliedCourses" value="BFOAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-266015">Baseball Front Office Certificate</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-266017" name="appliedCourses" value="BBFOAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-266017">Basketball Front Office Certificate</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-265925" name="appliedCourses" value="FFOAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-265925">Football Front Office Certificate</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-266018" name="appliedCourses" value="HFOAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-266018">Hockey Front Office Certificate</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-266019" name="appliedCourses" value="SFOAC" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-266019">Soccer Front Office Certificate</label>
          </div>
        </div>
      </div>
     
    </div>
    
    <div required="" class="checkbox-container">
    <div className='ai-column1'>  
        <div className="category">
         <h4 className='appnd_inter'>Apprenticeships & Internships</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-132422" name="appliedCourses" value="BBAS" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-132422">Basketball Apprenticeship</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-358241" name="appliedCourses" value="FBAAS" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-358241">Football Agent Apprenticeship</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-136330" name="appliedCourses" value="FBAS" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-136330">Football Scout Apprenticeship</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-136332" name="appliedCourses" value="SCAS" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-136332">Soccer Apprenticeship</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-courses-chosen-131331" name="appliedCourses" value="VIP" onChange={handleChange} />
            <label htmlFor="edit-courses-chosen-131331">Virtual Internship Program</label>
          </div>
        </div>
      </div>

    <div className='ai-column2'>
      <div className="category">
          <h4 className='appnd_inter'><img src={`${process.env.PUBLIC_URL}/images/appn/Degrees.png`} alt="degrees" /> Degrees</h4>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-cuc-degrees-masm" name="cuc_degrees" value="MASM" onChange={handleChange} />
            <label htmlFor="edit-cuc-degrees-masm">Master's of Professional Studies in Sports Management (FHSU)</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-cuc-degrees-masl" name="cuc_degrees" value="MASL" onChange={handleChange} />
            <label htmlFor="edit-cuc-degrees-masl">Master's in Sports Leadership (CUC)</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-cuc-degrees-mba" name="cuc_degrees" value="MBA" onChange={handleChange} />
            <label htmlFor="edit-cuc-degrees-mba">MBA in Sports Leadership (CUC)</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-cuc-degrees-edd" name="cuc_degrees" value="Ed.D" onChange={handleChange} />
            <label htmlFor="edit-cuc-degrees-edd">Ed.D. Sports Leadership (CUC)</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="edit-cuc-degrees-phd" name="cuc_degrees" value="Ph.D" onChange={handleChange} />
            <label htmlFor="edit-cuc-degrees-phd">Ph.D. Sports Leadership (CUC)</label>
          </div>
        </div>
        </div>
        <div className='ai-column3'></div>
      </div>
  </div>
</div>
{errors.appliedCourses &&
                    <span className="error-message">
                      {errors.appliedCourses}
                    </span>
                  }
</div>



{/* --------------------------------------ENDDDDDDDDDDSelect your top 5 areas of interest------------------------------------- */}




              <div className="form-row">
                <div className="form-group">
                  <label>Tell Us Your Story <span className="form-required" title="This field is required.">*</span></label>
                  <span >To help us understand you as an individual and not just an applicant, please tell us about 
                    why you are interested in taking this course.</span>
                  <textarea required name="story" value={formData.story} className='story-text-area' onChange={handleChange}></textarea>
                  {errors.story &&
                    <span className="error-message">
                      {errors.story}
                    </span>
                  }
                </div>
              </div>
              <div className="form-row form-item">
                <div className="form-group">
                  <label>Current Job</label>
                  <input className='smww-appn' type="text" name="field_current_job" value={formData.field_current_job} onChange={handleChange} />
                </div>
              </div>
              <div className="form-row form-item-app-highest-grade">
                <div className="form-group">
                  <label>Education Level Completed <span className="form-required" title="This field is required.">*</span></label>
                  <select className='smww-appn' required name="field_highest_grade" value={formData.field_highest_grade} onChange={handleChange}>
                    <option value="">Select Education Level</option>
                    <option value="High School Diploma">High School Diploma</option>
                    <option value="Some College">Some College</option>
                    <option value="Bachelor's Degree">Bachelor's Degree</option>
                    <option value="Master's Degree">Master's Degree</option>
                    <option value="Doctoral Degree">Doctoral Degree</option>
                    
                  </select>
                  {errors.field_highest_grade &&
                    <span className="error-message">
                      {errors.field_highest_grade}
                    </span>
                  }
                </div>
				
                <div className="form-group">
                {formData.showEducationFields && (
                <>
                  <label>Your School or University</label>
                  <input className='smww-appn' type="text" name="field_university" value={formData.field_university} onChange={handleChange} />
                  </>
                )}
                </div>
               
              </div>
              {formData.showEducationFields && (
                <>
              <div className="form-row">
                <div className="form-group">
                  <label>Major / Degree / Course Completed</label>
                  <input className='smww-appn' type="text" name="field_major" value={formData.field_major} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label>Last Attended</label>
                  <select className='smww-appn'  name="field_last_attended" value={formData.field_last_attended} onChange={handleChange}>
                  <option value="">Year</option>
                  {generateYearOptions()}
                  
                </select>
                </div>
              </div>
              </>
                )}
              <div className="form-row">
                <div className="form-group">
                  <label>Are you a member of the military?</label>
                  <div className="radio-container">
                    <div>
                      <input
						className='smww-appn'			
                        type="radio"
                        id="yesMilitary"
                        name="field_military"
                        value="Yes"
                        checked={formData.field_military === "Yes"}
                        onChange={handleChange}
                      />
                      <label htmlFor="yesMilitary">Yes</label>
                    </div>
                    <div>
                      <input
						className='smww-appn'			
                        type="radio"
                        id="noMilitary"
                        name="field_military"
                        value="No"
                        checked={formData.field_military === "No"}
                        onChange={handleChange}
                      />
                      <label htmlFor="noMilitary">No</label>
                    </div>
                  </div>
                </div>
                {formData.field_military === "Yes" && (
                  <div className="form-group">
                    <label>Please Provide Your Military ID Number</label>
                    <p>(We provide a Military Discount for those who have served)</p>
                    <input
					  className='smww-appn'			 
                      type="text"
                      name="field_military_id"
                      value={formData.field_military_id}
                      onChange={handleChange}
                    />    
                  </div>
                )}
              </div>
              <div className="form-row form-item">
                <div className="form-group">
                  <label>How did you hear about us?</label>
                  <select className='smww-appn' name="field_sports_recom" value={formData.field_sports_recom} onChange={handleChange}>
                    <option value="">Select</option>
                    <option value="College Professor">College Professor</option>
                    <option value="Dr. Lashbrook gave me his card">Dr. Lashbrook gave me his card</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Former Customer/Conference Attendee">Former Customer/Conference Attendee</option>
                    <option value="Google">Google</option>
                    <option value="Instagram">Instagram</option>
                    <option value="LinkedIn">LinkedIn</option>
                    <option value="Podcast">Podcast</option>
                    <option value="Radio Ad">Radio Ad</option>
                    <option value="Received an SMWW newsletter email">Received an SMWW newsletter email</option>
                    <option value="Referred by an SMWW Alumni">Referred by an SMWW Alumni</option>
                    <option value="Twitter">Twitter</option>
                    <option value="YouTube">YouTube</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              </div>

              <div className="form-row linkedin-url">
                <div className="form-group">
                  <label>Your LinkedIn URL</label>
                  <input className='smww-appn'  type="text" name="field_linkedin_url" value={formData.field_linkedin_url} onChange={handleChange} />
                  {errors.field_linkedin_url &&
                    <span className="error-message">
                      {errors.field_linkedin_url}
                    </span>
                  }
                </div>

              </div>              

          {/* Other fields for the third step */}
          <button className='continue-three smww-appn-button' type="submit" onClick={handleSubmitStep3}>{buttontext}</button>
          {disabled && (
            <div>
              <img src={`${process.env.PUBLIC_URL}/images/ajax-loader.gif`} alt="loader" className='loader' />
            </div>
          )}
          
        </>
      )}

<p className='disclaimer-txt'><strong>NOTE: You should receive an email confirmation of your application.</strong>
 If you do not receive an email confirmation, please email us at: <a href="mailto:info@smww.com">info@smww.com</a>
  Applying with SMWW will put you on SMWW's newsletter and SMS list which you can unsubscribe from at any time.
  <a target="_blank" href="https://www.sportsmanagementworldwide.com/privacy">Click here</a> to view our Privacy Policy.<br/ >
  <span className='bottom-span'>Sports Management Worldwide prohibits discrimination against its customers, employees, and applicants 
    for employment on the bases of race, color, national origin, age, disability, sex, gender identity, religion, reprisal, 
    and where applicable, political beliefs, marital status, familial or parental status, sexual orientation, or all or part 
    of an individual's income is derived from any public assistance program, or protected genetic information in employment 
    or in any program or activity conducted or funded by Sports Management Worldwide.</span>
</p>
     
    </form>
    </div>
   )}</>
  );

}

export default ApplicationForm;
