export const configData =  {  

    APP_URL: "/basic/web/api/",
    DOMAIN_URL: "/basic/web/",
    DRUPAL_APP_URL: "https://betasmww.preferati.net/api/",
    DRUPAL_DOMAIN_URL: "https://betasmww.preferati.net",
    AGENCY_DOMAIN_URL: "https://betasmwwagency.preferati.net",
    SCOUT_DOMAIN_URL: "https://betasmwwscout.preferati.net",
    GOOGLE_RECAPTCHA_SITE_KEY: '6LexjAMqAAAAABPAI-rFrNJCld43b3jFn6v3qDu3',
    DOMAIN_PUBLIC_URL: `${window.location.protocol}//${window.location.hostname}`
     }